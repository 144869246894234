import { useEffect, useMemo } from 'react';
import styles from './CoinBack202409Banner.module.scss';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import { track } from '@amplitude/analytics-browser';
import { getCoinBackPrimeSubEvent } from 'common/utils/user';
import classNames from 'classnames';
import { COIN_BACK_PRIME_EVENT_CODE } from 'common/utils';
import {
  EventAction,
  EventCategory,
  EventComponent,
  ScreenName,
  addEvent,
} from 'common/utils/pp_tracking';

const randomBgUrl = () => {
  return '/images/banners/coin-back-banner-2024-09.jpg';
  // const random = Math.floor(Math.random() * 2);
  // if (random === 1) return '/images/banners/coin-back-banner-2024-07.jpg';
  // return '/images/banners/anime_banner/anime-banner-bg-2.jpg';
};

const CoinBack202409Banner = ({ className }: { className?: string }) => {
  const currentUser = useStore((state) => state.currentUser);
  const coinBackEvent = getCoinBackPrimeSubEvent(currentUser);
  const bgUrl = useMemo(() => {
    return randomBgUrl();
  }, []);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const setShowingCoinBackPrimeSubCampaignPopup = useGlobalStore(
    (state) => state.setShowingCoinBackPrimeSubCampaignPopup
  );

  useEffect(() => {
    addEvent({
      action: EventAction.PageView,
      category: EventCategory.Campaign,
      component: EventComponent.CoinBackPrimeSubCampaignBanner,
      screen: ScreenName.HomeTimeline,
      timestamp: new Date().toISOString(),
      tracking_params: [
        {
          value: 'view',
          label: EventComponent.CoinBackPrimeSubCampaignBanner,
        },
      ],
    });
  }, []);
  if (!coinBackEvent) return <></>;
  return (
    <div
      className={classNames(
        styles.coinBackCampaignBannerWrapper,
        className ?? ''
      )}
      onClick={() => {
        if (!currentUser) {
          showLogin({});
          return;
        }
        track('Click Top Banner', {
          banner_type: COIN_BACK_PRIME_EVENT_CODE,
        });
        setShowingCoinBackPrimeSubCampaignPopup(true);
      }}
    >
      <img src={bgUrl} width="100%" />
    </div>
  );
};

export default CoinBack202409Banner;
